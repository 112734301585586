<template>
  <div id="openAccount">
    <div class="content-box">
      <div class="inner">
        <div class="title">{{ $t('openDemoAdditAcc.title') }}</div>
        <div class="sub-title">
          <i18n path="verificationStatus.accOpeningVerification.subTitle">
            <template v-slot:link>
              <a href="https://puprime.com/account-types" target="_blank">{{
                $t('verificationStatus.accOpeningVerification.here')
              }}</a>
            </template>
          </i18n>
        </div>
        <div class="liveaccount-box">
          <div v-if="!success">
            <el-form class="form-box" :model="form" :rules="formRules" ref="form">
              <el-row :gutter="40">
                <el-col :sm="12" :xs="24">
                  <el-form-item prop="tradingPlatform">
                    <Selection
                      v-model="form.tradingPlatform"
                      :placeholder="$t('common.formValidation.answerReq')"
                      :options="tradingPlatformOptions"
                      filterable
                      selectType="underline"
                      dataTestId="platform"
                    >
                      <template #title>
                        {{
                          $t('register.accOpeningVerification.createLiveAccount.choose', {
                            dynamic: $t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
                          })
                        }}
                      </template>
                    </Selection>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :xs="24">
                  <el-form-item prop="accountType">
                    <Cascader
                      :title="
                        $t('register.accOpeningVerification.createLiveAccount.choose', {
                          dynamic: $t('register.accOpeningVerification.createLiveAccount.accountType')
                        })
                      "
                      v-model="accountTypeSelection"
                      :options="accountTypeList"
                    ></Cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="40">
                <el-col :sm="12" :xs="24">
                  <el-form-item prop="currency">
                    <Selection
                      v-model="form.currency"
                      :placeholder="$t('common.formValidation.answerReq')"
                      :options="currencyOptions"
                      filterable
                      selectType="underline"
                      dataTestId="currency"
                    >
                      <template #title>
                        {{
                          $t('register.accOpeningVerification.createLiveAccount.choose', {
                            dynamic: $t('register.accOpeningVerification.createLiveAccount.accountCurrency')
                          })
                        }}
                      </template>
                    </Selection>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :xs="24">
                  <el-form-item prop="leverage">
                    <Selection
                      v-model="form.leverage"
                      :placeholder="$t('common.formValidation.answerReq')"
                      :options="leverageOptions"
                      selectType="underline"
                      dataTestId="leverage"
                    >
                      <template #title>
                        {{ $t('openAdditAcc.chooseLeverage') }}
                      </template>
                    </Selection>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :sm="12" :xs="24">
                  <el-form-item prop="balance">
                    <Selection
                      v-model="form.balance"
                      :placeholder="$t('common.formValidation.answerReq')"
                      :options="balanceOptions"
                      selectType="underline"
                      dataTestId="balance"
                    >
                      <template #title>
                        {{ $t('openAdditAcc.chooseBalance') }}
                      </template>
                    </Selection>
                  </el-form-item>
                </el-col>
              </el-row>

              <div class="action-area mt-5">
                <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit('form')">
                  <div>{{ $t('common.button.confirm') }}</div>
                </Button>
              </div>
            </el-form>
          </div>
          <Result backUrl="homeDemo" :selfImg="true" v-else>
            <template v-slot:img>
              <div class="steps">
                <img src="@/assets/images/openAccount/step01.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step02.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step03.png" alt="" />
              </div>
            </template>
            <p v-html="$t('openDemoAdditAcc.succ')"></p>
          </Result>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TextInput from '@/components/common/TextInput.vue';
import Cascader from '@/components/common/Cascader.vue';
import Result from '@/components/Result';
import { apiCreateDemoAccount } from '@/resource';
import { checkAgreedTNC } from '@/util/validation';

export default {
  name: 'openDemoAccount',
  components: {
    Result,
    Button,
    Selection,
    Cascader,
    TextInput
  },
  data() {
    return {
      form: {
        tradingPlatform: `mt4`,
        accountType: null,
        currency: null,
        leverage: null,
        balance: null
      },
      formRules: {
        tradingPlatform: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.platErr')
          }
        ],
        accountType: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.accTypeErr')
          }
        ],
        currency: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.currError')
          }
        ],
        leverage: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.leverageErr')
          }
        ],
        balance: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.balanceErr')
          }
        ]
      },
      success: false,
      accountTypeSelection: null
    };
  },
  watch: {
    'form.accountType': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal) this.form.currency = '';
      }
    },
    accountTypeSelection: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (Array.isArray(newVal)) {
          this.form.accountType = newVal[newVal.length - 1];
        } else {
          this.form.accountType = newVal;
        }
      }
    }
  },
  computed: {
    tradingPlatformOptions() {
      let tradingPlatformData = this.$config.openLiveAccount.choosePlatform(this.regulator);

      tradingPlatformData = tradingPlatformData.filter(item => item.value !== 'MTS');

      // set default platform
      this.form.tradingPlatform = tradingPlatformData[0].value;

      return tradingPlatformData.map(item => ({
        label: this.$t(item.label),
        value: item.value
      }));
    },
    accountTypeList() {
      // For MT4 & MT5
      let result = this.$config.openLiveAccount.mt4DemoChooseType(this.regulator);

      // set default accountType
      if (this.accountTypeSelection == null) {
        this.form.accountType = result[0].value;
        this.accountTypeSelection = [result[0].value];
      }

      return result;
    },
    currencyOptions() {
      const result = this.$config.openLiveAccount.chooseCurrency(this.regulator, this.form.accountType);

      // check current choose accountType
      this.form.currency = result[0].value;

      return result;
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    leverageOptions() {
      let options = this.$config.openLiveAccount.chooseLeverage(this.regulator);

      if (this.form.leverage === null) {
        const leverageOption = options.find(item => item.value === 500);
        this.form.leverage = leverageOption ? leverageOption.value : options[0].value;
      }

      return options;
    },
    balanceOptions() {
      let options = this.$config.openLiveAccount.chooseBalance(this.regulator).map(option => ({
        ...option,
        label: option.value.toLocaleString()
      }));

      if (this.form.balance === null) {
        this.form.balance = options[0].value;
      }

      return options;
    }
  },
  methods: {
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form });
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true;
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-title {
  margin-bottom: 40px;

  /deep/ a {
    color: $pug-blue;
    text-decoration: underline;
  }
}

.liveaccount-box {
  border-radius: 8px;
  background-color: $white;
  max-width: 1200px;
  padding: 68px 40px;

  /deep/ .success {
    max-width: 904px;

    .steps {
      margin-bottom: 80px;

      img {
        margin-bottom: 0;
        display: inline-block;
      }

      .line {
        display: inline-block;
        border-width: $border-width;
        border-color: $blue;
        border-style: dashed;
        width: 157px;
        margin: 0 20px 0;
      }
    }

    .success-content {
      max-width: 600px;

      p {
        font-size: 15px;
        color: $dark-gray;
        line-height: 1.667;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 576px) {
    padding: 68px 120px;
  }
}

.checkBox {
  /deep/ .el-checkbox {
    display: contents;
  }

  /deep/ .tnc {
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    @include rtl-sass-prop(margin-left, margin-right, 15px);

    .showTnc {
      display: inline-block;
      color: $pug-blue;
      cursor: pointer;
    }
  }
  /deep/ ul {
    @include rtl-sass-prop(padding-left, padding-right, 40px);

    li {
      font-size: 16px;
      line-height: 24px;
      list-style-type: decimal;
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.action-button {
  width: 160px;
  height: 56px;
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}

.text-higlight {
  color: $pug-blue;
}

.mr-2 {
  margin-right: 10px;
}

.info-box {
  display: flex;
  border-radius: 8px;
  background-color: #ebf4ff;
  padding: 14px 12px;
  align-items: center;

  /deep/ strong {
    font-weight: bold;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.info-img {
  margin-right: 10px;
  flex-shrink: 0;
  align-self: flex-start;
}

.info-box-text {
  div + div {
    margin-top: 20px;
  }
}

/deep/ .el-dialog__body {
  padding: 50px 50px 45px !important;

  p {
    font-size: 15px;
    color: #626d71;
    line-height: 1.8;

    &.red {
      color: $red;
      margin-bottom: 40px;
    }
  }

  .line {
    border-width: 0.59px;
    border-color: rgb(160, 160, 160);
    border-style: dashed;
    margin: 20px 0;
  }

  .btn-box {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
    margin-bottom: 20px;
  }
}

#openAccount {
  .inner {
    max-width: 1200px;
  }
}
</style>
